import { API } from "./api";

export const chartService = {
  GetNumberofAccountinYear(year) {
    return API.get(`accounts/GetNumberofAccountinYear`, {
      params: {
        year,
      },
    });
  },
  GetNumberofSalonOwnerinYear(year) {
    return API.get(`accounts/GetNumberofSalonOwnerinYear`, {
      params: {
        year,
      },
    });
  },
  GetAppointmentbyStatusByAdmin(status, year) {
    return API.get(`appointments/GetAppointmentbyStatusByAdmin`, {
      params: {
        status,
        year,
      },
    });
  },
  GetRevenueByAdmin(year) {
    return API.get(`appointments/GetRevenueByAdmin`, {
      params: {
        year,
      },
    });
  },
  GetCommissionByAdmin(year) {
    return API.get(`appointments/GetCommissionByAdmin`, {
      params: {
        year,
      },
    });
  },
  GetAppointmentRatioByStatus(year) {
    return API.get(`appointments/GetAppointmentRatioByStatus`, {
      params: {
        year,
      },
    });
  },
  GetAppointmentRatioInYear(year) {
    return API.get(`appointments/GetAppointmentRatioInYear`, {
      params: {
        year,
      },
    });
  },
  GetNumberofSalonOwnerApproved(year) {
    return API.get(`accounts/GetNumberofSalonOwnerApproved`);
  },
  GetNumberofCustomerApproved(year) {
    return API.get(`accounts/GetNumberofCustomerApproved`);
  },
  GetAllOverStatisticSalonOwner(year) {
    return API.get(`/appointments/AdminOverallStatistic`);
  },
};
//accounts/GetNumberofSalonOwnerApproved
//accounts/GetNumberofCustomerApproved
