import { API } from "./api";

export const configServices = {
  getAllConfig(page, size) {
    return API.get("configs/GetAllConfig", {
      params: {
        page,
        size,
      },
    });
  },
  getAllConfigByType(type, page, size) {
    return API.get("configs/GetConfigByType", {
      params: {
        type,
        page,
        size,
      },
    });
  },
  getConfig(data) {
    return API.post("configs/GetConfigIdofCommissioRate", data);
  },
  GetConfigById(id) {
    return API.get(`configs/GetConfigById/${id}`);
  },
  CreateConfig(data) {
    return API.post(`configs/CreateSubscriptionConfig`, data);
  },
  CreateConfigCommssion(data) {
    return API.post(`configs/CreateCommissionConfig`, data);
  },
  UpdateConfig(id, data) {
    return API.put(`configs/UpdateConfig/${id}`, data);
  },
  DeleteConfig(id) {
    return API.delete(`configs/DeleteConfig/${id}`);
  },
};
