import {
  GET_NUMBER_ACCOUNT_BY_YEAR,
  GET_NUMBER_SALON_BY_YEAR,
  GET_APPOINTMENT_CANCELED_BY_YEAR,
  GET_APPOINTMENT_FAILED_BY_YEAR,
  GET_APPOINTMENT_SUCCESS_BY_YEAR,
  GET_APPOINTMENT_RATIO_BY_STATUS,
  GET_APPOINTMENT_RATIO_BY_YEAR,
  GET_COMMISSION_BY_YEAR,
  GET_REVENUE_BY_YEAR,
  GET_CUSTOMER_APPROVED,
  GET_SALON_APPROVED,
  GET_ALL_OVER,
} from "./action";

const initialState = {
  numberAccountByYear: {},
  numberSalonByYear: {},
  numberAppointmentSuccesseByYear: {},
  numberAppointmentFailedByYear: {},
  numberAppointmentCanceledByYear: {},
  numberAppointmentRatiodByStatus: {},
  numberAppointmentRatiodByYear: {},
  numberRevenueByYear: {},
  numberCommissionByYear: {},
  numberofCustomerApproved: 0,
  numberofSalonOwnerApproved: 0,
  getAllOverStatistic: {},
};

const chartServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NUMBER_ACCOUNT_BY_YEAR:
      return {
        ...state,
        numberAccountByYear: action.payload,
      };
    case GET_NUMBER_SALON_BY_YEAR:
      return {
        ...state,
        numberSalonByYear: action.payload,
      };
    case GET_APPOINTMENT_SUCCESS_BY_YEAR:
      return {
        ...state,
        numberAppointmentSuccesseByYear: action.payload,
      };
    case GET_APPOINTMENT_FAILED_BY_YEAR:
      return {
        ...state,
        numberAppointmentFailedByYear: action.payload,
      };
    case GET_APPOINTMENT_CANCELED_BY_YEAR:
      return {
        ...state,
        numberAppointmentCanceledByYear: action.payload,
      };
    case GET_APPOINTMENT_RATIO_BY_STATUS:
      return {
        ...state,
        numberAppointmentRatiodByStatus: action.payload,
      };
    case GET_APPOINTMENT_RATIO_BY_YEAR:
      return {
        ...state,
        numberAppointmentRatiodByYear: action.payload,
      };
    case GET_REVENUE_BY_YEAR:
      return {
        ...state,
        numberRevenueByYear: action.payload,
      };
    case GET_COMMISSION_BY_YEAR:
      return {
        ...state,
        numberCommissionByYear: action.payload,
      };
    case GET_CUSTOMER_APPROVED:
      return {
        ...state,
        numberofCustomerApproved: action.payload,
      };
    case GET_SALON_APPROVED:
      return {
        ...state,
        numberofSalonOwnerApproved: action.payload,
      };
    case GET_ALL_OVER:
      return {
        ...state,
        getAllOverStatistic: action.payload,
      };
    default:
      return state;
  }
};

export default chartServiceReducer;
