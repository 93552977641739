import { API } from "./api";

export const appointmentService = {
  GetAppointmentByStatus(
    status,
    page,
    size,
    startDate,
    endDate,
    salonName,
    customerName
  ) {
    return API.get(`appointments/GetAppointmentByStatus/${status}`, {
      params: {
        page,
        size,
        startDate,
        endDate,
        salonName,
        customerName,
      },
    });
  },
};
