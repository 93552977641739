import { toast } from "react-toastify";
import { configServices } from "../../services/configService";

export const ALL_CONFIG = "ALL_CONFIG";
export const CONFIG_ID = "CONFIG_ID";
export const CONFIG_BY_ID = "CONFIG_BY_ID";

export const allConfig = (list) => {
  return {
    type: ALL_CONFIG,
    payload: list,
  };
};
export const configId = (list) => {
  return {
    type: CONFIG_ID,
    payload: list,
  };
};
export const configById = (detail) => {
  return {
    type: CONFIG_BY_ID,
    payload: detail,
  };
};

export function getAllConfig(page, size) {
  return (dispatch) => {
    configServices
      .getAllConfig(page, size)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(allConfig(response.data));
        } else {
          console.log("getAllConfig fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while fetching all config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}
export function getAllConfigByType(type, page, size) {
  return (dispatch) => {
    configServices
      .getAllConfigByType(type, page, size)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(allConfig(response.data));
        } else {
          console.log("getAllConfig fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while fetching all config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}

export function getConfigId(data) {
  return (dispatch) => {
    configServices
      .getConfig(data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(configId(response.data));
        } else {
          console.log("không nhận được gói");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while fetching all config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}

export function GetConfigById(id) {
  return (dispatch) => {
    configServices
      .GetConfigById(id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(configById(response.data));
        } else {
          console.log("GetConfigById fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while fetching config id:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}

export function updateConfig(id, data) {
  return (dispatch) => {
    configServices
      .UpdateConfig(id, data)
      .then((response) => {
        console.log("config data update: ", response);
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllConfig(1, 10));
          dispatch(GetConfigById(id));
          toast.success("Cập nhật config thành công");
        } else {
          // toast.error("get all syllabus to fail");
          console.log("fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while updateConfig config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}

export function CreateNewConfig(data) {
  return (dispatch) => {
    configServices
      .CreateConfig(data)
      .then((response) => {
        console.log("config data create: ", response);
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllConfig(1, 10));
          toast.success("Tạo config thành công");
        } else {
          // toast.error("get all syllabus to fail");
          console.log("fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while CreateConfig config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}
export function CreateNewConfigCommission(data) {
  return (dispatch) => {
    configServices
      .CreateConfigCommssion(data)
      .then((response) => {
        console.log("config data create: ", response);
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllConfig(1, 10));
          toast.success("Tạo config thành công");
        } else {
          // toast.error("get all syllabus to fail");
          console.log("fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while CreateConfig config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}

export function DeleteConfig(id) {
  return (dispatch) => {
    configServices
      .DeleteConfig(id)
      .then((response) => {
        console.log("config data delete: ", response);
        if (response.status === 200 || response.status === 201) {
          dispatch(getAllConfig(1, 10));
          toast.success("Xóa config thành công");
        } else {
          // toast.error("get all syllabus to fail");
          console.log("fail");
        }
      })
      .catch((error) => {
        // Xử lý lỗi nếu có
        console.error("Error while CreateConfig config:", error);
        // Nếu bạn muốn dispatch một action để xử lý lỗi, bạn có thể thực hiện ở đây
      });
  };
}
